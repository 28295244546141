export enum Permission {
  Administrator = "admin",
  UnionAdministrator = "union_admin",
  UnionTimekeeper = "union_timekeeper",
  UnionDispatcher = "union_dispatcher",
  EmployerTimekeeper = "employer_timekeeper",
  EmployerTest = "employer_test",
  EmployerSupervisor = "employer_supervisor",
  UnionAdminReadonly = "union_admin_readonly",
  UnionSupervisor = "union_supervisor",
  ReeferMechanic = "reefer_mechanic",
  ILAAdmin = "ila_admin",
  USMXAdmin = "usmx_admin",
  EmployerUploads = "employer_uploads",
  SingleStepApprover = "single_step_approver",
  CallRecords = "call_records",
  StevedoringAssociation = "stevedoring_association",

  PortReadAccess = "Port_Administrator_Read_Access",
  PortWriteAccess = "Port_Administrator_Write_Access",

  TerminalReadAccess = "Terminal_Administrator_Read_Access",
  TerminalWriteAccess = "Terminal_Administrator_Write_Access",

  PierBerthReadAccess = "DockingStation_Administrator_Read_Access",
  PierBerthWriteAccess = "DockingStation_Administrator_Write_Access",

  DeptReadAccess = "DeptCode_Administrator_Read_Access",
  DeptWriteAccess = "DeptCode_Administrator_Write_Access",
  
  OccCodeReadAccess = "OccCode_Administrator_Read_Access",
  OccCodeWriteAccess = "OccCode_Administrator_Write_Access",

  SteadyOccCodeReadAccess = "SteadyOccCodeRates_Administrator_Read_Access",
  SteadyOccCodeWriteAccess = "SteadyOccCodeRates_Administrator_Write_Access",

  OccTypeReadAccess = "OccType_Administrator_Read_Access",
  OccTypeWriteAccess = "OccType_Administrator_Write_Access",

  OccRateReadAccess = "OccRate_Administrator_Read_Access",
  OccRateWriteAccess = "OccRate_Administrator_Write_Access",

  UnionReadAccess = "Union_Administrator_Read_Access",
  UnionWriteAccess = "Union_Administrator_Write_Access",

  DepartmentReadAccess = "Department_Administrator_Read_Access",
  DepartmentWriteAccess = "Department_Administrator_Write_Access",

  VesselReadAccess = "Vessel_Administrator_Read_Access",
  VesselWriteAccess = "Vessel_Administrator_Write_Access",

  AdminReadAccess = "Admin_Administrator_Read_Access",
  AdminWriteAccess = "Admin_Administrator_Write_Access",

  SettingsReadAccess = "Settings_Administrator_Read_Access",
  SettingsWriteAccess = "Settings_Administrator_Write_Access",

  EmployerReadAccess = "Employer_Administrator_Read_Access",
  EmployerWriteAccess = "Employer_Administrator_Write_Access",

  CertificationsReadAccess = "Certifications_Read_Access",
  CertificationsWriteAccess = "Certifications_Write_Access",

  SuspensionReadAccess = "Suspension_Read_Access",
  SuspensionWriteAccess = "Suspension_Write_Access",

  WorkerReadAccess = "Worker_Administrator_Read_Access",
  WorkerWriteAccess = "Worker_Administrator_Write_Access",

  WorkerIncidentReadAccess = "WorkerIncident_Read_Access",
  WorkerIncidentWriteAccess = "WorkerIncident_Write_Access",

  ReeferMechanicReadAccess = "ReeferMechanic_Read_Access",
  ReeferMechanicWriteAccess = "ReeferMechanic_Write_Access",

  PowerBiReportsReadAccess = "Power_Bi_Reports_Read_Access",
  PowerBiReportsWriteAccess = "Power_Bi_Reports_Write_Access",

  UploadFtpAccess = "Upload_Ftp_Access",

  PermissionAdministrator = "PermissionAdministrator_Full_Access",
  SendGroupSmsAccess = "Send_Group_Sms_Access",

  WorkerCallReadAccess = "Worker_Call_Read_Access",

  WorkerCallRecordReadAccess = "Worker_Call_Record_Read_Access",
  WorkerCallRecordWriteAccess = "Worker_Call_Record_Write_Access"
}
